<template>
    <div class="revenue-chart">
        <p class="chart-title" v-if="chartParams">
            {{chartParams.name}}走势图
        </p>
        <div id="chartBox" v-if="boss.revenueChart && boss.revenueChart.length"></div>
        <p class="no-data" v-else>暂无数据</p>
    </div>
</template>

<script>
  import table from '@/api/table'
	import {mapState} from 'vuex'

	export default {
		name:"revenue",
		props:{
			chartParams:Object,
			timeParams:Object,
		},
		data(){
			return {
				xList:null,
				yList:null,
			}
		},
		computed:{
			...mapState(['userStatus','boss'])
		},
		watch:{
			chartParams:{
				handler(newVal,oldVal){
					let {chartParams,timeParams} = this
					if(! newVal){
						return
					}
					if(! chartParams){
						return
					}
					if(! ! oldVal && newVal.filed == oldVal.filed){
						return
					}
					if(! timeParams){
						return
					}
					this.getRevenueChartData().then(() =>{
						this.resolveChartData()
						this.renderChart()
					})
				},
				deep:true,
			},
			timeParams:{
				handler(newVal,oldVal){
					let {chartParams} = this
					if(! newVal){
						return
					}
					if(! chartParams){
						return
					}
					if(! ! oldVal && newVal.startTime === oldVal.startTime){
						return
					}
					this.getRevenueChartData().then(() =>{
						this.resolveChartData()
						this.renderChart()
					})
				},
				deep:true,
			},
			'userStatus.curs_node_id_2':{
				handler(){
					let {chartParams,timeParams} = this
					if(! chartParams){
						return
					}
					if(! timeParams){
						return
					}
					this.getRevenueChartData().then(() =>{
						this.resolveChartData()
						this.renderChart()
					})
				},
				deep:true,
				immediate:true
			}
		},
		methods:{

			/**
			 * 获取营收首页7日走势图
			 */
			getRevenueChartData(){
				let {chartParams,timeParams} = this
        let {filed} = chartParams
				let {type,startTime,endTime} = timeParams
				let params = {
					chartType:filed,
					timeType:type,
					startTime,
					endTime,
				}
				return new Promise((resolve,reject) =>{
					table.getRevenueChartData(params).then(res =>{
						this.$store.commit("updateRevenueChart",res.data)
						resolve()
					}).catch(err =>{
						console.log("err",err.info)
						reject()
					})
				})
			},

			/**
			 * 处理图表数据
			 */
			resolveChartData(){
				let {boss:{revenueChart}} = this
				let xList = []
				let yList = []
				for(let item of revenueChart){
					for(let key in item){
						xList.push(key)
						yList.push(item[key])
					}
				}
				this.xList = xList
				this.yList = yList
			},

			/**
			 * 渲染7日走势图
			 */
			renderChart(){
				let chart = this.$echarts.init(document.querySelector('#chartBox'))
				let {xList,yList} = this
				var color = '#0066ff'
				let areaBack = 'rgba(0,102,255,0.2)'
				let tooltipBack = 'rgba(50,50,50,0.6)'
				let YUnit = this.getChartYUnit()
				let YName = this.getChartYName()
				let option = {
					theme:'light',
					grid:{
						left:'5%',
						right:'6%',
						bottom:'3%',
						top:'10%',
						containLabel:true
					},
					xAxis:{
						type:'category',
						boundaryGap:false,
						data:xList,
						axisTick:{
							alignWithLabel:true
						},
						axisPointer:{
							snap:true,
						},
						axisLine:{
							show:false
						},
						axisTick:{
							show:false,
						},
					},
					yAxis:{
						type:'value',
						splitNumber:5,
						axisLine:{
							lineStyle:{
								color:'#dfdfdf'
							}
						},
						axisTick:{
							show:false,
						},
						axisLabel:{
							inside:true,
							color:'#333333'
						},
					},
					tooltip:{
						trigger:'axis',
						textStyle: {
							color: "#FFFFFF" //设置文字颜色
						},
						backgroundColor:tooltipBack,
						formatter:function(params){
							let {axisValue,value} = params[0]
							let time = axisValue.split('.')
							let dateTime = time[0]+'月'+time[1]+'日'
							// let valueKb = parseFloat(value).toLocaleString('en-US')
							let valueKb = value
							return "日期：" + dateTime + "<br/>" + YName + ' : ' + valueKb +YUnit
						},
            				//强制去掉tooltip的overflow
            				position: function (point, params, dom, rect, size) {
            					dom.style.transform = "translateZ(0)";
            				}
					},
					series:[{
						data:yList,
						type:'line',
						smooth:true,
						areaStyle:{
							normal:{
								color:areaBack
							}
						},
						// 显示数值
						itemStyle:{
							normal:{
								color,
								lineStyle:{color},
								label:{show:false}
							}
						},
					}]
				};
				chart.setOption(option)
			},

			/**
			 * 走势图获取Y轴中文单位
			 */
			getChartYUnit(){
				let {chartParams} = this
                let filed = !!chartParams?chartParams.filed:'total_turnover'
				switch(filed){
					case 'total_turnover':
						return '元'
						break
					case 'net_turnover':
						return '元'
						break
					case 'cash_income':
						return '元'
						break
					case 'discount_gift':
						return '元'
						break
					case 'passenger_flow':
						return '人'
						break
					case 'main_item_points':
						return '个'
						break
					case 'member_recharge':
						return '元'
						break
					case 'new_member':
						return '人'
						break
          default:
            return '元'
					// case 'member_refill':
					// 	return '元'
					// 	break
				}
			},

			/**
			 * 走势图获取Y轴中文单位
			 */
			getChartYName(){
				let {chartParams} = this
				let filed = !!chartParams?chartParams.filed:'total_turnover'
				switch(filed){
					case 'total_turnover':
						return '金额'
						break
					case 'net_turnover':
						return '金额'
						break
					case 'cash_income':
						return '金额'
						break
					case 'discount_gift':
						return '金额'
						break
					case 'passenger_flow':
						return '人数'
						break
					case 'main_item_points':
						return '点数'
						break
					case 'member_recharge':
						return '金额'
						break
					case 'new_member':
						return '人数'
						break
          default:
            return '金额'
					// case 'member_refill':
					// 	return '金额'
					// 	break
				}
			},
		},
	}
</script>

<style lang="scss" type="text/scss" scoped>
    @import "~@/assets/style/charts.scss";
</style>
